'use strict';

/**
 * Imports SCSS for Webpack parsing and sets strict mode
 */
import '../styles/universal.scss';

/**
 * Vendor libraries and plugin settings
 */
// Instant.page
import './vendor/instant.page';
// Lazysizes
import './vendor/lazysizes';

/**
 * Universal Scripting
 */
// Defer loading of iframe content
let vidDefer = document.getElementsByTagName('iframe');

for (var i=0; i<vidDefer.length; i++) {
  if(vidDefer[i].getAttribute('data-src')) {
    vidDefer[i].setAttribute('src',vidDefer[i].getAttribute('data-src'));
  }
}

/**
 * Universal components
 */
// Scroll to Top
import './components/navigation/scroll-to-top';
// Video promotion
import './components/promotions/video';
// Photo promotion
import './components/promotions/photo';
// Footer newsletter signup
import './components/footer/newsletter-signup';
// Footer masthead and buttons
import './components/footer/masthead-buttons';
// Footer navigation menu
import './components/footer/navigation';
// Footer division grid
import './components/footer/division-grid';
// Footer contact info
import './components/footer/contact-info';
// Footer copyright
import './components/footer/copyright';
